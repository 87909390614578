@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap");

.authloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9;
}

.authloaderWrapper .authLoader {
  margin-bottom: 8px;
}

.navactive .MuiButtonBase-root {
  background-color: rgba(255, 255, 255, 0.08);
}

.loginWrapper {
  height: 100vh;
  width: 100%;
  background: url("./Assets/max.jpg") center no-repeat;
  background-size: cover;
}

.loginWrapper .loginCard {
  height: 55vh;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  border-radius: 10px;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.loginWrapper .loginCard .loginFormWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 200px;
}

.CategoryWrapper {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ReactCrop__image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}
